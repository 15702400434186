import React, { useEffect } from 'react';

const TikTokPixel = () => {
  useEffect(() => {
    const loadTikTokPixel = () => {
      if (window.ttq) return; // Prevent multiple injections

      (function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        let ttq = w[t];

        if (!ttq) {
          ttq = [];
          w[t] = ttq;
        }

        ttq.methods = [
          'page',
          'track',
          'identify',
          'instances',
          'debug',
          'on',
          'off',
          'once',
          'ready',
          'alias',
          'group',
          'enableCookie',
          'disableCookie',
          'holdConsent',
          'revokeConsent',
          'grantConsent',
        ];

        ttq.setAndDefer = function (t, e) {
          t[e] = function (...args) {
            t.push([e, ...args]);
          };
        };

        for (let i = 0; i < ttq.methods.length; i += 1) {
          ttq.setAndDefer(ttq, ttq.methods[i]);
        }

        ttq.instance = function (t) {
          const e = ttq._i[t] || [];
          for (let n = 0; n < ttq.methods.length; n += 1) {
            ttq.setAndDefer(e, ttq.methods[n]);
          }
          return e;
        };

        ttq.load = function (e, n) {
          const r = 'https://analytics.tiktok.com/i18n/pixel/events.js';
          ttq._i = ttq._i || {};
          ttq._i[e] = [];
          ttq._i[e]._u = r;
          ttq._t = ttq._t || {};
          ttq._t[e] = +new Date();
          ttq._o = ttq._o || {};
          ttq._o[e] = n || {};
          const script = document.createElement('script');
          script.id = 'tiktok-pixel-script'; // Assign an ID to the script
          script.type = 'text/javascript';
          script.async = true;
          script.src = `${r}?sdkid=${e}&lib=${t}`;
          const firstScript = document.getElementsByTagName('script')[0];
          firstScript.parentNode.insertBefore(script, firstScript);
        };

        ttq.load('CVN83RJC77U9D5N73DR0'); // Replace with your Pixel ID
        ttq.page();
      })(window, document, 'ttq');
    };

    loadTikTokPixel();

    return () => {
      // Cleanup function: Remove script on unmount
      const script = document.getElementById('tiktok-pixel-script');
      if (script) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default TikTokPixel;
