import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const steps = [
  'Cosa vuoi assicurare?',
  'Qual è la tua situazione assicurativa?',
  'Classe di merito',
  'Dati Assicurativi',
];

const requiredFields = [
  'targa',
  'nome',
  'cognome',
  'codicefiscale',
  'datanascita',
  'indirizzoresidenza',
  'citta',
  'marca',
  'modello',
  'alimentazione',
  'email',
  'numero',
];

export default function StepperPrev() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [requestToSend, setRequestToSend] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const totalSteps = () => steps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    console.log('DEBUG ', requestToSend);
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!requestToSend?.[field]) {
        newErrors[field] = 'Questo campo è obbligatorio';
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      alert('Attenzione, inserire tutti i campi obbligatori');
      return;
    }

    if (isLastStep()) {
      // aggiungi contollo campi obbligatorio
      const formData = new FormData();
      Object.keys(requestToSend).forEach((key) => {
        formData.append(key, requestToSend[key]);
      });
      formData.append('key', 'q#FfXt#Uu188443p9D');
      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data,multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        },
      };
      axios
        .post('https://preventivo.oraclesbroker.it/services/doRequest.php', formData, axiosConfig)
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res);
          setOpen(true);
        })
        .catch((err) => {
          console.log('ERROR: ', err);
        });
    } else {
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleClickButton = (key, value, step) => {
    handleChangeFix(value, [key]);
    if (key === 'newInsurance' && value === 'Polizza in scadenza') {
      console.log(key, value, step);
      handleChangeFix('Ho già un attestato di rischio da altro veicolo di mia proprietà attivo, venduto o altro', [
        'newClasse',
      ]);
      step += 1;
    }
    setActiveStep(step);
    if (step >= 3) {
      window.scrollTo(0, 0);
    }
  };

  const handleChangeFix = (value, name) => {
    setRequestToSend((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box style={{ textAlign: 'center' }} sx={{ width: '100%', textAlign: 'center' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton variant="contained" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <br />
          <Typography variant="h4" sx={{ mt: 2, mb: 1, textAlign: 'center', color: '#447484' }}>
            {activeStep === 0 && 'Cosa vuoi assicurare'}
            {activeStep === 1 && 'Situazione assicurativa'}
            {activeStep === 2 && 'Classe di Merito'}
            {activeStep === 3 && 'Dati Assicurativi'}
          </Typography>
          <br />

          {/* Qui vanno gli step */}
          {activeStep === 0 && <FirstStep handleClickButton={handleClickButton} />}
          {activeStep === 1 && <SecondStep handleClickButton={handleClickButton} />}
          {activeStep === 2 && <ThirdStep handleClickButton={handleClickButton} />}
          {activeStep === 3 && (
            <FourthStep handleChangeFix={handleChangeFix} requestToSend={requestToSend} errors={errors} />
          )}

          <br />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, margin: '3%' }}>
            <Button variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Indietro
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep >= 3 && (
              <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
                {activeStep === 3 ? 'Invia' : 'Avanti'}
              </Button>
            )}
          </Box>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Richiesta Inviata
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La richiesta è stata inviata correttamente, verrete ricontattati il prima possibile.
          </Typography>
          <br />
          <Button onClick={() => setOpen(false)} variant="contained">
            Chiudi
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
