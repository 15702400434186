
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid,Card,Container, Typography, } from '@mui/material';
import CookieConsent from "react-cookie-consent";
// sections
import {
  AppWidgetSummary,

} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
const isMobile = width <= 768;

  return (
    <div>
      <Helmet>
        <title> Oraclesbroker | Preventivo </title>
      </Helmet>
      <Typography textAlign={'center'}>
      <Grid  item xs={12} sm={12} md={12}>
      <Card container 
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        bgcolor: '#447484',
        marginLeft: '6%',
        marginRight:'6%'
        }}>
       {!isMobile ?<img height={200} src={"https://oraclesbroker.it/wp-content/uploads/2022/03/cropped-logo_800x450.png"} alt="Logo" /> : <img height={120} src={"https://oraclesbroker.it/wp-content/uploads/2022/03/cropped-logo_800x450.png"}  alt="Logo" />}   
      </Card>  
      </Grid>
      </Typography>
      <Container style={{ alignSelf: 'center' }}  maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary title="" total={714000} icon={'ant-design:android-filled'} />
          </Grid>
        </Grid>
      </Container>
      <CookieConsent
        location="top"
        buttonText="Accetto"
        declineButtonText="Rifiuto"
        enableDeclineButton
        cookieName="userCookieConsentOraClesBroker"
        style={{ background: "black", color: "#fff" }}
        buttonStyle={{ background: "#4CAF50", color: "#fff", fontSize: "18px" }}
        declineButtonStyle={{ background: "#d9534f", color: "#fff", fontSize: "18px" }}
        expires={365}
     
      >
        "Questo sito utilizza i cookie"<br /><br />
        Per offrirti un'esperienza di navigazione migliore, utilizziamo cookie per analizzare il traffico e personalizzare i contenuti. Continuando a navigare, acconsenti all'uso dei cookie. Puoi leggere la nostra politica sui cookie per ulteriori informazioni e gestire le tue preferenze.
        <a rel="noreferrer" target='_BLANK' href="https://oraclesbroker.it/contatti/" style={{ color: "#FFD700" }}>Leggi di più</a>
      </CookieConsent>
    </div>
  );
}
