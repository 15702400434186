import * as React from 'react';
import { TextField, Typography, Box, MenuItem, Checkbox, Grid, Button } from '@mui/material';
import marche from '../../../utils/marche-auto.json';

export default function formAssicurativo(props) {
  const { handleChangeFix, requestToSend, errors } = props;

  return (
    <>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '80%' },
        }}
      >
        <Typography variant="h4" sx={{ mt: 2, mb: 1, textAlign: 'center', color: '#447484' }}>
          Inserire Dati
        </Typography>
        <TextField
          value={requestToSend?.targa}
          onChange={(e) => handleChangeFix(e.target.value?.toUpperCase(), 'targa')}
          color="primary"
          focused
          id="targa"
          required
          label="Targa Veicolo"
          variant="outlined"
          helperText={errors.targa || 'Inserire La Targa'}
          error={!!errors.targa}
        />
        <TextField
          required
          onChange={(e) => {
            handleChangeFix(e.target.value, 'nome');
          }}
          value={requestToSend?.nome}
          color="primary"
          focused
          id="nome"
          label="Nome proprietario"
          variant="outlined"
          helperText={errors.nome || 'Nome proprietario'}
          error={!!errors.nome}
        />
        <TextField
          required
          onChange={(e) => {
            handleChangeFix(e.target.value, 'cognome');
          }}
          value={requestToSend?.cognome}
          color="primary"
          focused
          id="cognome"
          label="Cognome proprietario"
          variant="outlined"
          helperText={errors.cognome || 'Cognome proprietario'}
          error={!!errors.cognome}
        />
        <TextField
          required
          onChange={(e) => {
            handleChangeFix(e.target.value, 'codicefiscale');
          }}
          value={requestToSend?.codicefiscale}
          color="primary"
          focused
          id="codicefiscale"
          label="Codice Fiscale/P.Iva"
          variant="outlined"
          helperText={errors.codicefiscale || 'Codice Fiscale/P.Iva'}
          error={!!errors.codicefiscale}
        />
        <TextField
          value={requestToSend?.datanascita}
          date
          onChange={(e) => handleChangeFix(e.target.value, 'datanascita')}
          type="date"
          color="primary"
          focused
          id="datanascita"
          label="Data di nascita"
          variant="outlined"
          helperText={errors.datanascita || 'Data di nascita'}
          error={!!errors.datanascita}
          required
        />
        <TextField
          onChange={(e) => {
            handleChangeFix(e.target.value, 'indirizzoresidenza');
          }}
          value={requestToSend?.indirizzoresidenza}
          color="primary"
          focused
          id="indirizzoresidenza"
          label="Indirizzo di residenza"
          variant="outlined"
          required
          helperText={errors.indirizzoresidenza || 'Indirizzo di residenza'}
          error={!!errors.indirizzoresidenza}
        />
        <TextField
          onChange={(e) => {
            handleChangeFix(e.target.value, 'citta');
          }}
          value={requestToSend?.citta}
          color="primary"
          focused
          id="citta"
          label="Città di residenza"
          variant="outlined"
          required
          helperText={errors.citta || 'Città di residenza'}
          error={!!errors.citta}
        />
        <TextField
          onChange={(e) => {
            handleChangeFix(e.target.value, 'email');
          }}
          value={requestToSend?.email}
          color="primary"
          focused
          email
          id="email"
          label="Email"
          variant="outlined"
          required
          type="email"
          helperText={errors.email || 'Email'}
          error={!!errors.email}
        />
        <TextField
          onChange={(e) => {
            handleChangeFix(e.target.value, 'numero');
          }}
          value={requestToSend?.numero}
          color="primary"
          focused
          type="tel"
          id="numero"
          label="Numero"
          variant="outlined"
          required
          helperText={errors.numero || 'Numero'}
          error={!!errors.numero}
        />
        <TextField
          value={requestToSend?.marca || ''}
          onChange={(e) => handleChangeFix(e.target.value, 'marca')}
          color="primary"
          focused
          id="marca"
          label="Marca"
          variant="outlined"
          select
          required
          helperText={errors.marca || 'Seleziona La Marca'}
          error={!!errors.marca}
        >
          <MenuItem key={''} value={''}>
            Seleziona
          </MenuItem>
          {marche.marche.map((marca) => (
            <MenuItem key={marca} value={marca}>
              {marca}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          value={requestToSend?.modello}
          onChange={(e) => handleChangeFix(e.target.value, 'modello')}
          focused
          label="Seleziona il modello"
          color="primary"
          variant="outlined"
          required
          helperText={errors.modello || 'Seleziona Il Modello'}
          error={!!errors.modello}
        />
        <TextField
          value={requestToSend?.alimentazione || ''}
          onChange={(e) => handleChangeFix(e.target.value, 'alimentazione')}
          color="primary"
          id="alimentazione"
          select
          focused
          label="Alimentazione"
          variant="outlined"
          required
          helperText={errors.alimentazione || "Seleziona L' Alimentazione"}
          error={!!errors.alimentazione}
        >
          <MenuItem default key={'BENZINA'} value={'BENZINA'}>
            BENZINA
          </MenuItem>
          <MenuItem key={'DIESEL'} value={'DIESEL'}>
            DIESEL
          </MenuItem>
          <MenuItem key={'ELETTRICA'} value={'ELETTRICA'}>
            ELETTRICA
          </MenuItem>
          <MenuItem key={'GAS'} value={'GAS'}>
            GAS
          </MenuItem>
          <MenuItem key={'IBRIDA'} value={'IBRIDA'}>
            IBRIDA
          </MenuItem>
        </TextField>
        {requestToSend?.newInsurance === 'Sto per acquistare un Nuovo Veicolo' &&
          requestToSend?.newClasse !== 'Non ho un attestato di rischio disponibile' && (
            <>
              <TextField
                value={requestToSend?.targaRecuperoClasse}
                onChange={(e) => handleChangeFix(e.target.value, 'targaRecuperoClasse')}
                helperText="Targa del Veicolo Assicurato per Recupero Classe"
                focused
                label="Targa del Veicolo Assicurato per Recupero Classe"
                color="primary"
                id="targaRecuperoClasse"
                variant="outlined"
              />
              <TextField
                value={requestToSend?.nomeRecuperoClasse}
                onChange={(e) => handleChangeFix(e.target.value, 'nomeRecuperoClasse')}
                helperText="Nome e cognome proprietario"
                focused
                label="Nome e cognome proprietario"
                color="primary"
                id="nomeRecuperoClasse"
                variant="outlined"
              />
            </>
          )}
        <hr />
        <Typography variant="h4" sx={{ mt: 2, mb: 1, py: 1, textAlign: 'center', color: '#447484' }}>
          Coperture Assicurative:
        </Typography>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '80%' },
            textAlign: 'left',
            marginLeft: '8%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} xm={6} xl={6}>
              <Checkbox
                checked={requestToSend?.rca}
                onChange={(e) => {
                  handleChangeFix(e.target.checked, 'rca');
                }}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
              />
              <Typography component="strong">R.C.A. Responsabilità Civile Auto</Typography>
            </Grid>

            {requestToSend?.rca && (
              <Grid item xs={12} xm={6} xl={6}>
                <TextField
                  value={requestToSend?.rcamassimale}
                  onChange={(e) => {
                    handleChangeFix(e.target.value, 'rcamassimale');
                  }}
                  select
                  color="primary"
                  focused
                  id="outlined-basic"
                  label="Massimale"
                  variant="outlined"
                  helperText="Massimale"
                >
                  <MenuItem key={'1'} value={'1'}>
                    7.750.000,00€
                  </MenuItem>
                  <MenuItem key={'2'} value={'2'}>
                    8.000.000,00€
                  </MenuItem>
                  <MenuItem key={'3'} value={'3'}>
                    10.000.000,00€
                  </MenuItem>
                  <MenuItem key={'4'} value={'4'}>
                    15.000.000,00€
                  </MenuItem>
                  <MenuItem key={'5'} value={'5'}>
                    25.000.000,00€
                  </MenuItem>
                  <MenuItem key={'6'} value={'6'}>
                    50.000.000,00€
                  </MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.rinunciarivalsa}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'rinunciarivalsa');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Rinuncie alle Rivalse</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.assistenzastradale}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'assistenzastradale');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Assistenza Stradale (es. Carro Attrezzi)</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.infortuniconducente}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'infortuniconducente');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Infortuni del Conducente</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.tutelalegale}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'tutelalegale');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Tutela Legale</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.furtoincendio}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'furtoincendio');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Furto e Incendio</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.cristalli}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'cristalli');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Cristalli</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.eventiAtmosferici}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'eventiAtmosferici');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Eventi Atmosferici</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.eventiSocioPolitici}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'eventiSocioPolitici');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Eventi Sociopolitici</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.kaskoCollisione}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'kaskoCollisione');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">KASKO Collisione</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.kasko}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'kasko');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">KASKO Completa</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.imprevisti}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'imprevisti');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Garanzia Imprevisti</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.bonusprotetto}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'bonusprotetto');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Bonus Protetto</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.danniVeicoloNonAssicurato}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'danniVeicoloNonAssicurato');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Danni da veicolo non assicurato</Typography>
          </Grid>
          <Grid item xs={12} xm={6} xl={6}>
            <Checkbox
              checked={requestToSend?.danniAnimaliSelvatici}
              onChange={(e) => {
                handleChangeFix(e.target.checked, 'danniAnimaliSelvatici');
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
            />
            <Typography component="strong">Danni da animali selvatici</Typography>
          </Grid>
        </Box>
        <Box>
          <hr />
          <Typography variant="h4" sx={{ mt: 2, mb: 1, py: 1, textAlign: 'center', color: '#447484' }}>
            Clausole e Informazioni Aggiuntive:
          </Typography>
          <TextField
            onChange={(e) => {
              handleChangeFix(e.target.value, 'tipoguida');
            }}
            value={requestToSend?.tipoguida}
            select
            color="primary"
            focused
            id="outlined-basic"
            label="Tipologia di Guida"
            variant="outlined"
            helperText="Tipologia di Guida"
          >
            <MenuItem key={'Esperta'} value={'Esperta'}>
              Esperta
            </MenuItem>
            <MenuItem key={'Libera'} value={'Libera'}>
              Libera
            </MenuItem>
            <MenuItem key={'Esclusiva'} value={'Esclusiva'}>
              Esclusiva
            </MenuItem>
          </TextField>
          <TextField
            onChange={(e) => {
              handleChangeFix(e.target.value, 'carrozzerieConvenzionate');
            }}
            value={requestToSend?.carrozzerieConvenzionate}
            select
            color="primary"
            focused
            id="outlined-basic"
            label="Desideri rivolgerti alle Carrozzerie Convenzionate:"
            variant="outlined"
            helperText="Desideri rivolgerti alle Carrozzerie Convenzionate:"
          >
            <MenuItem key={'si'} value={'si'}>
              Si
            </MenuItem>
            <MenuItem key={'no'} value={'no'}>
              No
            </MenuItem>
          </TextField>
          <TextField
            onChange={(e) => {
              handleChangeFix(e.target.value, 'gps');
            }}
            value={requestToSend?.gps}
            select
            color="primary"
            focused
            id="outlined-basic"
            label="Desideri inserire il GPS Satellitare sul tuo Veicolo:"
            variant="outlined"
            helperText="Desideri inserire il GPS Satellitare sul tuo Veicolo:"
          >
            <MenuItem key={'si'} value={'si'}>
              Si
            </MenuItem>
            <MenuItem key={'no'} value={'no'}>
              No
            </MenuItem>
          </TextField>
          <TextField
            onChange={(e) => {
              handleChangeFix(e.target.value, 'gps');
            }}
            value={requestToSend?.custodito}
            select
            color="primary"
            focused
            id="outlined-basic"
            label="Il tuo Veicolo dove verrà tenuto:"
            variant="outlined"
            helperText="Il tuo Veicolo dove verrà tenuto:"
          >
            <MenuItem key={'In Strada Privata'} value={'In Strada Privata'}>
              In Strada Privata
            </MenuItem>
            <MenuItem key={'In Area Recintata'} value={'In Area Recintata'}>
              In Area Recintata
            </MenuItem>
            <MenuItem key={'In Garage o Box'} value={'In Garage o Box'}>
              In Garage o Box
            </MenuItem>
          </TextField>
        </Box>
        <Box>
          <hr />
          <Typography variant="h4" sx={{ mt: 2, mb: 1, py: 1, textAlign: 'center', color: '#447484' }}>
            Allegare i Documenti richiesti
          </Typography>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            {/* Pulsante: Caricamento Fronte */}
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth>
                Patente Fronte/Retro
                <input
                  type="file"
                  hidden
                  multiple
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length === 2) {
                      handleChangeFix(files[0], 'patentefrontefile');
                      handleChangeFix(files[1], 'patenteretrofile');
                    } else {
                      alert('Inserire 2 file Fronte/Retro Patente');
                    }
                  }}
                />
              </Button>
              {requestToSend?.patentefrontefile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.patentefrontefile.name}
                </Typography>
              )}
              {requestToSend?.patenteretrofile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.patenteretrofile?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            {/* Pulsante: Caricamento Fronte */}
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth>
                Carta di Identità Fronte/Retro
                <input
                  type="file"
                  hidden
                  accept=".jpg, .jpeg, .png, .pdf"
                  multiple
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length === 2) {
                      handleChangeFix(files[0], 'cartafrontefile');
                      handleChangeFix(files[1], 'cartaretrofile');
                    } else {
                      alert('Inserire 2 file Fronte/Retro Carta di Identità');
                    }
                  }}
                />
              </Button>
              {requestToSend?.cartafrontefile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.cartafrontefile.name}
                </Typography>
              )}
              {requestToSend?.cartaretrofile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.cartaretrofile?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            {/* Pulsante: Caricamento Fronte */}
            <Grid item xs={12} sm={6}>
              <Button variant="contained" component="label" fullWidth>
                Libretto Fronte/Retro
                <input
                  type="file"
                  hidden
                  accept=".jpg, .jpeg, .png, .pdf"
                  multiple
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files.length === 2) {
                      handleChangeFix(files[0], 'librettofrontefile');
                      handleChangeFix(files[1], 'librettoretrofile');
                    } else {
                      alert('Inserire 2 file Fronte/Retro Libretto');
                    }
                  }}
                />
              </Button>
              {requestToSend?.librettofrontefile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.librettofrontefile.name}
                </Typography>
              )}
              {requestToSend?.librettoretrofile && (
                <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                  {requestToSend?.librettoretrofile?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
