import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Grid, Container } from '@mui/material';

export default function ThirdStep(props) {
  const { handleClickButton } = props;
  return (
    <Container>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 345, boxShadow: 3, textAlign: 'center' }}>
            <Button
              fullWidth
              onClick={() =>
                handleClickButton(
                  'newClasse',
                  'Ho già un attestato di rischio da altro veicolo di mia proprietà attivo, venduto o altro',
                  3
                )
              }
              variant="contained"
              color="primary"
            >
              Ho già un attestato di rischio da altro veicolo di mia proprietà attivo, venduto o altro
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 345, boxShadow: 3, textAlign: 'center' }}>
            <Button
              fullWidth
              onClick={() =>
                handleClickButton(
                  'newClasse',
                  "Posso utilizzare l'attestato di rischio di una persona nel mio nucleo familiare",
                  3
                )
              }
              variant="contained"
              color="primary"
            >
              Posso utilizzare l'attestato di rischio di una persona nel mio nucleo familiare
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 345, boxShadow: 3, textAlign: 'center' }}>
            <Button
              fullWidth
              onClick={() => handleClickButton('newClasse', 'Non ho un attestato di rischio disponibile', 3)}
              variant="contained"
              color="primary"
            >
              Non dispongo di un attestato di rischio disponibile
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
