import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        // Home direttamente alla pagina /app
        { element: <Navigate to="/app" />, index: true }, // Home route reindirizza a /app
        { path: 'app', element: <DashboardAppPage /> }, // La pagina effettiva dell'app
      ],
    },
    // Tutti gli altri percorsi reindirizzati alla home
    {
      path: '*',
      element: <Navigate to="/app" replace />, // Redirige tutto a /app
    },
  ]);

  return routes;
}