import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Grid, Container } from '@mui/material';

export default function SecondStep(props) {
  const { handleClickButton } = props;
  return (
    <Container>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 345, boxShadow: 3, textAlign: 'center' }}>
            <Button
              fullWidth
              onClick={() => handleClickButton('newInsurance', 'Sto per acquistare un Nuovo Veicolo', 2)}
              variant="contained"
              color="primary"
            >
              Sto per acquistare un Nuovo Veicolo
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: 345, boxShadow: 3, textAlign: 'center' }}>
            <Button
              fullWidth
              onClick={() => handleClickButton('newInsurance', 'Polizza in scadenza', 2)}
              variant="contained"
              color="primary"
            >
              Polizza in scadenza
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
