// @mui
import * as React from 'react';
import PropTypes from 'prop-types';
import { Card,Button,Typography} from '@mui/material';
import StepperPrev from './stepper';
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, color = 'primary', sx }) {
  const [visiblePreventivo, setSisiblePreventivo] = React.useState(false);
  
return (
    <>
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        bgcolor: 'white',
        ...sx,
      }}>
      <Button onClick={() => { setSisiblePreventivo(true) }} variant="contained" color="primary">FAI UN PREVENTIVO GRATUITO</Button>
      
    </Card>
    {!visiblePreventivo&&
    (<Typography marginTop={'20px'} textAlign={'center'} variant="h4" color={"primary"} sx={{ mb: 5 }}>
          La polizza online che ti fa vivere tutto il buono dell'assicurazione
    </Typography>)}
    {visiblePreventivo&&<Card
      sx={{
        boxShadow: 0,
        textAlign: 'center',
        ...sx,
      }}>
      <StepperPrev/>
    </Card>}
    </>
  );
}
